import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLogout } from "../../../hooks/useLogout";
import assets from "../../../utils/Assets";
import withOutsideClickNurse from "./withOutsideClickNurse";

const NurseMenu = React.forwardRef(({ setOpen, open }, ref) => {
  const { refetch, data: logoutData } = useLogout();
  const useAuth = () => {
    const user = localStorage.getItem("nurseID");
    return Boolean(user);
  };
  const user = useAuth();
  const location = useLocation();
  const navigation = useNavigate();

  const logout = () => {
    refetch();
    if (logoutData?.status === "SUCCESS") {
      localStorage.removeItem("nurseAuth");
    }
    localStorage.removeItem("nurseID");
    localStorage.removeItem("nurseAuth");
    localStorage.removeItem("expires_in");
    localStorage.removeItem("nurse_refresh_token");
    navigation("/login");
    setOpen(!open);
  };

  return (
    <>
      {user && (
        <div className="navbar" ref={ref}>
          <div className="navbar__left">
            <div className="navbar__logo">
              <img
                src="https://static.meragoapp.com/images/menuLogo.png"
                alt="logo"
                width="55px"
                height="55px"
              />
            </div>

            <div className="navbar__items nav__more__left">
              <Link
                to="nurse-dashboard"
                className={
                  location.pathname.includes("/nurse-dashboard")
                    ? "sidebar_active"
                    : ""
                }
              >
                AlertU Services
              </Link>
            </div>
          </div>
          <div className="sideBar__main" onClick={() => setOpen(!open)}>
            <img src={assets.downArrow} alt="merago" />
          </div>
          {open ? (
            <div className="sidebar__conatiner agent__sidebar">
              {location.pathname !== "/agent" && (
                <button onClick={logout}>Logout</button>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
});

export default withOutsideClickNurse(NurseMenu);
